import { graphql, PageProps } from 'gatsby';
import React from 'react';
import LayoutMain from '../components/layouts/LayoutMain';
import SEO from '../components/seo/SEO';
import LayoutTotLife from '../components/layouts/layoutTotLife';

const TotLifePage = ({ data: { allPrismicWelltotBlogBlog } }: PageProps<TotLifePageQuery>) => {
  return (
    <LayoutMain>
      <SEO title="Tot Life" description="Tot Life" />
        <LayoutTotLife
            topicId=""
            articleSummaries={allPrismicWelltotBlogBlog.edges}
        />

    </LayoutMain>
  );
};

export const query = graphql`
  query TotLifePage {
    allPrismicWelltotBlogBlog(sort: { fields: last_publication_date, order: DESC }) {
      edges {
        node {
          id
          uid
          url
          data {
            title {
              text
            }
            body {
              text
            }
            blog_image {
              alt
              gatsbyImageData(layout: CONSTRAINED, width: 300, aspectRatio: 1.333)
            }
          }
        }
      }
    }
  }
`;

export default TotLifePage;
